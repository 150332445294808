import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/sivi_data/V1";
const API_SERVICE_ARRAY = "/api/sivi_data/ServiceArray/V1";

export default {
  getAll: (payload) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_unidadproductoraservicio ",
          type: "string"
        },
        {
          name: "@page",
          value: payload.currentPage,
          type: "int"
        }
      ]
    };

    if (payload.filter !== "all") {
      const { filter, search } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "activo",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${filter}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value: "idunidadproductoraservicio|denominacion",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%`,
        type: "string"
      });
    } else if (payload.search) {
      const { search } = payload;

      request._petitions.push({
        name: "@filtros_OR",
        value: "idunidadproductoraservicio|denominacion",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%`,
        type: "string"
      });
    }

    console.log(request);

    return post(API_SERVICE_QUERY, request);
  },
  addOne: (payload) => {
    const request = {
      _type: 2,
      _table: "mas_unidadproductoraservicio",
      _petitions: [
        {
          name: "idunidadproductoraservicio",
          value: payload.idunidadproductoraservicio,
          type: "string|unique"
        },
        {
          name: "denominacion",
          value: payload.denominacion,
          type: "string|unique"
        },
        {
          name: "activo",
          value: "1",
          type: "int"
        }
      ]
    };

    console.log(request);
    return post(API_SERVICE, request);
  },

  editOne: (payload, idunidadproductoraservicio, isUnique) => {
    const request = {
      _type: 3,
      _table: "mas_unidadproductoraservicio",
      _petitions: [
        {
          name: "idunidadproductoraservicio",
          value: payload.idunidadproductoraservicio,
          type: "string"
        },
        {
          name: "denominacion",
          value: payload.denominacion,
          type: isUnique ? "string|unique" : "string"
        },
        {
          name: "activo",
          value: payload.activo,
          type: "int"
        }
      ]
    };
    return put(API_SERVICE + "/" + idunidadproductoraservicio, request);
  },
  deleteMany: (payload) => {
    const request = {
      _type: 3,
      _table: "mas_unidadproductoraservicio",
      _petitions: [
        {
          name: "@ids",
          value: payload.join("|"),
          type: "string"
        },
        {
          name: "eliminado",
          value: payload.map(() => "1").join("|"),
          type: "string"
        }
      ]
    };

    return put(API_SERVICE_ARRAY, request);
  },
  changeStateMany: (payload) => {
    // console.log(payload);
    const request = {
      _type: 3,
      _table: "mas_unidadproductoraservicio",
      _petitions: [
        {
          name: "@ids",
          value: payload.ids.join("|"),
          type: "string"
        },
        {
          name: "activo",
          value: payload.activos.join("|"),
          type: "string"
        }
      ]
    };

    return put(API_SERVICE_ARRAY, request);
  }
};
